import * as React from "react";
import { Input, ViewSave, ZMenu, checkIsImageURL } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Chip, Divider, Grid } from "@mui/material";
import { css as customCss } from '../../content/Style'
// import Details from "./Details";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import Info from "../regular/Info";
import HBox from "../extra/HBox";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ZModal from "../../libs/ZModal";
import { useNavigate } from "react-router-dom";
import StatusBox from "./StatusBox";
import Details from "./Details";


export default function ProjectBoard(props) {
    const navigate = useNavigate()
    const [status, setStatus] = React.useState([]);
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState({});
    let authState = useSelector((state) => state.auth.data)
    let projectState = useSelector((state) => state.project?.data)
    let userState = useSelector((state) => state.user?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.PROJECT.GET.ACTION, payload: 0
        })
        dispatch({
            type: REQUESTS.USER.GET.ACTION, payload: 0
        })
        setStatus(["To Do", "In Progress", "Blocked", "Review", "Testing", "Closed", "Pause"])
    }, [])

    const onSubmit = (e, data) => {
        console.log("data :", data)

        if (data.image?.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.TASK.PUT.ACTION : REQUESTS.TASK.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.TASK.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.GROUP.GET.ACTION, payload: 0 })
            }
        })
    }
    // No need to override in normal edit
    const onEdit = (data, showmodel, loader, setFormData) => {
        let user = data.user
        if (user) {
            delete user.id
        }
        let newData = { ...data, ...user }
        setFormData(newData)
        showmodel(true)
    }
    const ExpandView = ({ id, total, allStatus }) => {
        const [tasks, setTasks] = React.useState()
        const [loaded, setLoaded] = React.useState(false)

        const loadData = (status) => {
            let url = `tasks/0/?filter_by=parent_id=${id},status='${status}'`
            if (status == "Total") {
                url = `tasks/0/?filter_by=parent_id=${id}`
            }
            dispatch({
                type: REQUESTS.TASK.GET.ACTION,
                payload: url,
                callback: (resp) => {
                    setTasks(resp.results)
                }
            })

        }
        return <Box>
            <StatusBox {...allStatus} onClick={(e, status) => { loadData(status); }} />
            <Button onClick={() => { setTasks([]) }}> Close</Button>
            {tasks && tasks.map((task) => {
                return <Box sx={{ border: '1px solid #f3f3f3', p: 1, my: 1 }}>
                    <Chip size="small" label={task?.id} sx={{ ml: 1 }} />
                    <h4 style={{ margin: '6px' }}>{task?.name}</h4>
                    <Box sx={{ background: '#f3f3f3', p: 2, pl: 3, my: 1 }}>
                        <Box> <span dangerouslySetInnerHTML={{ __html: task?.description }}></span></Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', mt: 5 }}>
                            <Chip size="small" label={task?.status} />
                            <Chip size="small" label={task?.assignee} sx={{ ml: 1 }} />
                        </Box>
                    </Box>
                    <Button size="small" variant="outlined" color="primary" onClick={() => {
                        dispatch({
                            type: REQUESTS.COMMENT.GET.ACTION, payload: `comment/tasks/${task?.id}/`, callback: (resp) => {
                                console.log("comments", resp)
                                setMore({ id: task.id, resp: resp })
                                setShowModal(true)
                            }
                        })
                    }}>Comments</Button>
                </Box>
            })}
        </Box>

    }

    return <>
        <ZModal showModal={showModal} onAction={() => { setShowModal(false) }}>
            <Details data={more} />
        </ZModal>
        <Grid container spacing={1}>
            <Grid item xs={12} md={10}>
                <ViewSave dataList={projectState?.results}
                    onSubmit={onSubmit}
                    headerList={["name"]}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    title={"Projects"}
                    renderCol={(data, key) => {
                        let blocked = data?.additional.tickets_blocked
                        let closed = data?.additional.tickets_closed
                        let progress = data?.additional.tickets_inprogress
                        let review = data?.additional.tickets_review
                        let pause = data?.additional.tickets_pause
                        let testing = data?.additional.tickets_testing
                        let todo = data?.additional.tickets_todo
                        let total = blocked + closed + progress + pause + testing + todo + review
                        let _status = {
                            blocked: blocked,
                            closed: closed,
                            progress: progress,
                            review: review,
                            pause: pause,
                            testing: testing,
                            todo: todo,
                            total: total,
                        }
                        return <>

                            <Box sx={{ width: '100%' }}>
                                <h4 style={{ padding: 4, borderRadius: 8, marginBottom: 8, background: '#d3f7f0' }}>{data.name}</h4>
                                <ExpandView id={data.id} allStatus={_status} />
                            </Box>
                        </>
                    }}
                    showActions={false}
                    hideEditBtn={true}
                    hideDelBtn={true}
                >
                    {
                        authState.permissions.includes("tasks.add_tickets") && <>
                            <Input type={"select"} label="Project" options={projectState?.results} text={"name"} name={"parent"} val="id"></Input>
                            <Input maxLength={100} label={"Task"} name={"name"} required={true} />
                            <Input type={"textarea"} label={"Description (supported html element)"} name={"description"} required={true} />
                            <Input type={"select"} label="priority" options={["high", "urgent", "law", "medium"]} name={"priority"} ></Input>
                            <Input type={"select"} label="status" options={status} name={"status"}></Input>
                            <Input type={"select"} label="Points(1 points is 4 hours)" options={[1, 2, 4, 8, 12]} name={"points"}></Input>
                            <Input type={"select"} label="Assigned by" options={userState?.results} text={"first_name"} name={"assigned_by"} val={"id"} ></Input>
                            <Input type={"select"} label="assigned to" options={userState?.results} text={"first_name"} name={"owner"} val={"id"}></Input>
                            <Input type={"number"} defaultValue="10" min={0} step={1} maxLength={100} label={"Amount of ticket"} name={"amount"} required={true} />

                        </>}
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={2}>
                <Info />
            </Grid>
        </Grid>
    </>
}