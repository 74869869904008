import { Box, Button, Chip, Divider, Grid } from "@mui/material";
export default function StatusBox({ progress,
    blocked,
    pause,
    review,
    total,
    testing,
    todo,
    closed,
    total_amount,
    onClick,
}) {
    total_amount = total_amount?total_amount:0
    let css = {
        minWidth: 80,
        mb: 1,
    }
    let subCss = {
        overflow: 'auto',
        textOverflow: 'auto',
        whiteSpace: ''
    }
    const status = ["To Do", "In Progress", "Blocked", "Review", "Testing", "Closed", "Pause"]
    onClick = onClick?onClick:(e,status)=>{}
    return <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box>
                <Chip
                    color="secondary"
                    variant="outlined"
                    size="small"
                    label={"Progress - " + progress}
                    sx={css}
                    onClick={(e)=>onClick(e, status[1])}
                />
                <br />
                <Chip
                    color="error"
                    variant="outlined"
                    size="small"
                    label={"Blocked - " + blocked}
                    sx={css}
                    onClick={(e)=>onClick(e, status[2])}
                />
                <br />
                <Chip
                    color="primary"
                    variant="outlined"
                    size="small"
                    label={"Pause - " + pause}
                    sx={css}
                    onClick={(e)=>onClick(e, status[6])}
                />

            </Box>
            <Box sx={{ ml: 1 }}>
                <Chip
                    variant="outlined"
                    size="small"
                    label={"Total - " + total}
                    sx={css}
                    onClick={(e)=>onClick(e, "Total")}

                />
                <br />
                <Chip
                    color="warning"
                    variant="outlined"
                    size="small"
                    label={"Testing - " + testing}
                    sx={css}
                    onClick={(e)=>onClick(e, status[4])}
                />
                <br />
                <Chip
                    color="success"
                    variant="outlined"
                    size="small"
                    label={"Review - " + review}
                    sx={css}
                    onClick={(e)=>onClick(e, status[3])}
                />
            </Box>
            <Box sx={{ ml: 1 }}>
                <Chip
                    size="small"
                    sx={{ ...css, border: '1px solid #ff0076' }}
                    label={"Todo - " + todo}
                    onClick={(e)=>onClick(e, status[0])}
                />
                <br />
                <Chip
                    sx={css}
                    color="success"
                    variant="outlined"
                    size="small"
                    label={"Closed - " + closed}
                    onClick={(e)=>onClick(e, status[5])}
                />
                <br />
                <Chip
                    sx={css}
                    color="success"
                    variant="outlined"
                    size="small"
                    label={"Price - " + total_amount}
                />
            </Box>
        </Box>
    </>
}