
import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "./Details";
import Info from "../regular/Info";

export default function Monthly(props) {
  let AttendanceViewState = useSelector((state) => state.calendar?.data)
  let dispatch = useDispatch()

  React.useEffect(() => {

    dispatch({
      type: REQUESTS.CALENDAR.GET.ACTION, payload: 0
    })
    console.log("monthly: ", AttendanceViewState)
  }, [])


  const cell = {
    padding: 20,
    textAlign: 'center',
    border: '1px solid #cfbdbd'

  }
  return <>
    <Grid container spacing={1}>
      <Grid item xs={12} md={12} >
        <Box sx={{...customCss.Form, background:"white",overflow:'auto'}}>
          <Table>
            <TableRow>
              {
                ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day_name) => {
                  return <th style={{textTransform:"capitalize", color:"gray",fontWeight:200}}>{day_name}</th>
                })
              }
            </TableRow>
            {AttendanceViewState?.results?.map((week) => {
              return <TableRow border='1'>
                {
                  ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"].map((day_name) => {
                    let cobj = {}
                    week.map((day) => { if (day.day_name == day_name) { cobj = day } })
                    if (cobj?.attendance?.tasks == 'holiday') {
                      return <td style={{ ...cell, background: '#fff4cb', color: "gray" }} title={cobj?.attendance?.tasks}></td>
                    } else if (cobj?.attendance?.tasks) {
                      return <td style={{ ...cell, background: '#90cf90', color: "white" }} title={cobj?.attendance?.tasks}>
                        <div>
                          <Typography>{cobj.day}</Typography>
                        </div>
                        <Typography>{cobj?.attendance?.tasks}</Typography>
                      </td>
                    } else {
                      return <td style={{ ...cell, background: '#ffdecb' }} title={"No Tasks"}><Typography>{cobj.day}</Typography></td>
                    }
                  })
                }
              </TableRow>
            })}
          </Table>
        </Box>
      </Grid>
      <Grid item xs={12} md={12} >

      </Grid>
    </Grid >
  </>

}