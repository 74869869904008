import SpaceDashboardSharpIcon from "@mui/icons-material/SpaceDashboardOutlined";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import AppSettingsAltIcon from '@mui/icons-material/AppSettingsAlt';

import WaterfallChartIcon from '@mui/icons-material/WaterfallChart';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import AddTaskIcon from '@mui/icons-material/AddTask';
import LineStyleSharpIcon from "@mui/icons-material/LineStyleSharp";
import PlaylistAddCheckIcon from "@mui/icons-material/PlaylistAddCheck";
import AccountBalanceIcon from '@mui/icons-material/AccountBalanceOutlined';
import Group from '../pages/adminstration/group'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import GradingIcon from '@mui/icons-material/Grading';
import UserPermission from '../pages/adminstration/user_permission'
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PeopleOutlined from '@mui/icons-material/PeopleOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import BookIcon from '@mui/icons-material/BookOutlined';
import VideocamIcon from '@mui/icons-material/VideocamOutlined';
import PhotoCameraIcon from '@mui/icons-material/PhotoCameraOutlined';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import GroupsIcon from '@mui/icons-material/GroupsOutlined';
import RequestPageOutlinedIcon from '@mui/icons-material/RequestPageOutlined';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';
import HailOutlinedIcon from '@mui/icons-material/HailOutlined';
import BarChartOutlined from '@mui/icons-material/BarChartOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SettingsIcon from '@mui/icons-material/Settings';
import GavelIcon from '@mui/icons-material/Gavel';
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion';

import Dashboard from "../pages/dashboard/Home";
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import Users from "../pages/users/Users";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Profile from "../pages/profile/Profile";
import Team from "../pages/users/Team";
import Attendance from "../pages/attendance/Attendance";
import Monthly from "../pages/attendance/Monthly";
import Task from "../pages/task/Task";
import Projects from "../pages/projects/Project"
//import Registration from "../pages/registration/Registration"
import PerformanceView from "../pages/performance/PerformanceView";
import Settings from "../pages/settings/Settings";
import IP from "../pages/auth/index/IndexPage";
import ContactUs from '../pages/auth/ContactUs'
import FileManager from "../pages/csv/FileManager";
import Reports from "../pages/reports/Reports";
import Terms$Conditions from "../pages/auth/Terms$Conditions";
import PubTerms$Conditions from "../pages/auth/PubTerms$Conditions";
import UsersBoard from "../pages/kanbanboard/UsersBoard";
import UserTask from "../pages/kanbanboard/UserTask";
import ProjectBoard from "../pages/kanbanboard/ProjectBoard";
export const HomePage = Dashboard; //its shows as loagout page
export const IndexPage = Login; //its shows as loagout page

export const anonyMenuItems = [
  {
    name: "Index",
    exact: true,
    to: "/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login />,
    // noMenu: true,
  },
  {
    name: "Login",
    exact: true,
    to: "/login/",
    icon: <PersonAddAlt1Icon sx={{ strokeWidth: 2 }} />,
    component: <Login hasForgot={"true"} />,
    // noMenu: true,
  },

  {
    name: "Register",
    exact: true,
    to: "/register/",
    icon: <PersonAddAlt1Icon />,
    component: <Register />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/reset/",
    icon: <PersonAddAlt1Icon />,
    component: <ForgotPassword title={"Reset Password"} submitLabel={"Reset"} />,
    // noMenu: true,
  },
  {
    name: "Reset Password",
    exact: true,
    to: "/changepassword/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Reset Password"} submitLabel={"Reset"} />,
    noMenu: true
  },
  {
    name: "Contact Us",
    exact: true,
    to: "/contact_us/",
    icon: <SpaceDashboardSharpIcon />,
    component: <ContactUs title={"Contact Us"} phone={"9656291915"} email="sujithvs.think@gmail.com" address={"xyz"} />,
    noMenu: true
  },
  {
    name: "Tc",
    exact: true,
    to: "/tcpub/",
    icon: <SpaceDashboardSharpIcon />,
    component: <PubTerms$Conditions/>,
    noMenu: true
  }
];

export const menuItems = [
  {
    name: "Dashboard",
    exact: true,
    permission: "*",
    to: "/dashboard/",
    icon: <SpaceDashboardSharpIcon sx={{ strokeWidth: 4 }} />,
    component: <Dashboard />,
  },
  {
    name: "Change Password",
    exact: true,
    to: "/changepassword/",
    permission: "*",
    icon: <SpaceDashboardSharpIcon />,
    component: <ForgotPassword changePassword title={"Change Password"} submitLabel={"Change"} />,
    noMenu: true
  },
  {
    name: "Role",
    exact: true,
    to: "/group/",
    permission: "permission",
    icon: <GroupsIcon />,
    component: <Group />,
  },
  {
    name: "User Permission",
    exact: true,
    to: "/user_permission/",
    permission: "permission",
    icon: <AdminPanelSettingsIcon />,
    component: <UserPermission />,
  },
  {
    name: "Users",
    exact: true,
    to: "/users/",
    permission: "user",
    icon: <PeopleOutlined />,
    component: <Users />,
  },
  {
    name: "Team",
    exact: true,
    to: "/team/",
    icon: <PeopleOutlined />,
    component: <Team />,
    noMenu: true
  },
  {
    name: "Profile",
    exact: true,
    to: "/profile/",
    icon: <AccountBalanceIcon />,
    component: <Profile title={"Sujith VS"} />,
    noMenu: true
  },
  {
    name: "Kanban Board",
    exact: true,
    to: "/kanbanboard/",
    permission:"user",
    icon: <BarChartOutlined />,
    component: <UsersBoard />,
  },
  {
    name: "Project Kanban Board",
    exact: true,
    to: "/projectkanbanboard/",
    permission:"user",
    icon: <BarChartOutlined />,
    component: <ProjectBoard/>,
  },
  {
    name: "Task",
    exact: true,
    permission: "tickets",
    to: "/task/",
    icon: <AddTaskIcon />,
    component: <Task />,
  },
  {
    name: "Attendance",
    exact: true,
    permission: "attendance",
    to: "/attendance/",
    icon: <AssignmentIndIcon />,
    component: <Attendance />,
  },
  {
    name: "Monthly",
    exact: true,
    permission: "attendance",
    to: "/monthly/",
    icon: <CalendarMonthIcon />,
    component: <Monthly />,
  },
  {
    name: "Project",
    exact: true,
    permission: "permission",
    to: "/projects/",
    icon: <AutoAwesomeMotionIcon />,
    component: <Projects/>,
  },
  {
    name: "Performance",
    exact: true,
    permission: "performance",
    to: "/performance/",
    icon: <GradingIcon />,
    component: <PerformanceView />,
  },
  {
    name: "Settings",
    exact: true,
    permission: "settings",
    to: "/settings/",
    icon: <SettingsIcon />,
    component: <Settings />,
  },
  {
    name: "Terms And Conditions",
    exact: true,
    permission: "permission",
    to: "/tc/",
    icon: <GavelIcon />,
    component: <Terms$Conditions/>,
  },
  {
    name: "UserTask",
    exact: true,
    permission: "permission",
    to: "/users_tasks/",
    icon: <GavelIcon />,
    component: <UserTask/>,
    noMenu:true,
  },
];
