import * as React from "react";
import { Input, ViewSave, checkIsImageURL,makeSearchQuery,makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "./Details";
import Info from "../regular/Info";

export default function Settings(props){
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState();
    let settingsState = useSelector((state) => state.settings?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
       dispatch({
           type: REQUESTS.SETTINGS.GET.ACTION, payload: 0,
           
        
         }
    
    )
    console.log("settings:",settingsState)

    }, [])

    const headerList = [
        {key:"id",label:"id",type:"string"},
      { key: 'checkin',label:"Check in",type:"string"},
       { key:'checkout', label: 'Check out', type: 'string' },
      {key:"project_max_due", label:"project Due" ,type:'string'},
      {key:"checkin_max_due", label:"Check_in due" ,type:'string'},
      {key:"holidays", label:"Holidays" ,type:'string'},
      {key:"max_casual_leave", label:"Casual leave" ,type:'string'},
      {key:"max_medical_leave", label:"Medical leave" ,type:'string'},


      
      
  ]

  return<>
  <Grid container spacing={1}>
          <Grid item xs={12} md={8}>
              <ViewSave 
              showHeader
              headerList={headerList}
              dataList={settingsState?.results}
              
              title="Settings"
              >

              </ViewSave>
              
              </Grid>

              </Grid>
              </>

  }
