import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "./Details";
import Info from "../regular/Info";

export default function PerformanceView(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState();
    let performanceViewState = useSelector((state) => state.performance?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.PERFORMANCE.GET.ACTION, payload: 0,


        }

        )
        console.log("Attendance:", performanceViewState)

    }, [])

    const headerList = [
        { key: "id", label: "id", type: "string" },
        { key: 'full_name', label: 'Name', type: 'string' },
        { key: "points", label: "Points", type: 'int' },
        { key: "reason", label: "Reason", type: 'int' },
        { key: "experience", label: "Experience", type: 'int' },


    ]

    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <ViewSave
                    headerList={headerList}
                    dataList={performanceViewState?.results}
                    title="View Performance"
                >

                </ViewSave>

            </Grid>

        </Grid>
    </>

}
