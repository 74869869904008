import axios from 'axios';
let AUTH_STORE ="auth"
export const setAuthStore = (AUTH)=>{
    AUTH_STORE = AUTH
}
console.log("AUTH STORE", AUTH_STORE)
// const baseURL = baseUrl()
const baseURL = process.env.REACT_APP_URL
axios.defaults.baseURL = baseURL
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';
// Get All Todos
let user = JSON.parse(localStorage.getItem(AUTH_STORE))
console.log("cached user", user)
if (user) {
    console.log("token applied", user)
    axios.defaults.headers.common['Authorization'] = "Token " + user.token
    console.log("IS TOKEN EQUAL" ,"9b9fda8045ede1c3d028b482d8166c13b7c3f9b5" == String(user?.token).trim())
    console.log("9b9fda8045ede1c3d028b482d8166c13b7c3f9b5" , user.token)
    console.log("header", axios.defaults.headers.common['Authorization'])
} else {
    axios.defaults.headers.common['Authorization'] = null
}

export const getRequest = async (url) => {
    try {
        const todos = await axios.get(url)
        return todos.data;
    } catch (err) {
        console.log("mesasge", err)
        return { error: true, message: err.message}
    }
}

// Create New Todo
export const postRequest = async (url, data) => {
    if (url == "token/") {
        axios.defaults.headers.common["Authorization"] = null;
    }
    console.log("post called", url)
    try {
        const todo = await axios.post(url, data)
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response?.data }
    }
}
export const putRequest = async (url, data) => {
    console.log("put called", url)
    try {
        const todo = await axios.put(url, data);
        return todo.data;
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}
//patch
export const patchRequest = async (url, data) => {
    console.log("patch called", url)
    try {
        const todo = await axios.patch(url, data);
        return todo.data;
    } catch (err) {
        console.log("mesasge", err.response.data)
        return { error: true, message: err.response.data }
    }
}


// Delete existed todo
export const delRequest = async (url, id) => {
    console.log(url, id)
    url = `${url}${id}/`
    try {
        let data = await axios.delete(url)
        console.log("DEL DATA", data);
        return id
    } catch (err) {
        return { error: true, message: err.response.data }
    }
}