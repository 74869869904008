import React from "react";
import XAutoComplete from "./XAutoComplate"
import './style.css'
import ImgInput from "./ImgInput";


const Input = React.forwardRef((props, ref) => {
    var objectConstructor = ({}).constructor;
    const panelCss = {
        border: 'none',
        marginBottom: 15,
        border: '1px solid #c5d6e9',
        ...props?.style
    }
    if (props?.type == 'textarea') {
        return <div className="did-floating-label-content" style={{...panelCss, border:'none',background:'none'}}>
            <textarea ref={ref} className="did-floating-input" {...props} placeholder=" " style={{ paddingTop: 6, height: 100 }}></textarea>
            <label className="did-floating-label">{props?.label ? props?.label : props?.name}</label>
        </div>

    }
    if (props?.type == 'image') {
        return <ImgInput {...props} />
    }
    if (props?.type == 'autocomplete') {
        return <XAutoComplete {...props} />
    }

    if (props?.type == 'select') {
        return <div className="did-floating-label-content" style={{...panelCss, border:'none',background:'none'}}>
            <select ref={ref} className="did-floating-input" {...props}>
                <option value="">Select {props?.label ? props?.label : props?.name}</option>
                {props?.options?.map((option, i) => objectConstructor == option.constructor ? <option key={i} value={option[props?.val]}>{option[props?.text]}</option> : <option>{option}</option>)}
            </select>
        </div>
    }
    if (props?.type === 'hidden') {
        return <input ref={ref} {...props} />
    }
    if (props?.type === 'checkbox' || props?.type === 'radio') {
        return <div style={{...panelCss, border:'none',background:'none'}}>
            <div style={{ paddingTop: 4, paddingBottom: 4 }}>
                <input ref={ref}  {...props} style={{ padding: 0 }} />
                <label style={{ position: 'relative' }}>
                    {props?.label ? props?.label : props?.name}
                </label>
            </div>
        </div>
    }
    return <div className="did-floating-label-content"
        style={{ ...panelCss, border:'none', background: 'none' }}>
        <input
            ref={ref}
            className="did-floating-input"
            {...props} style={{...panelCss, marginBottom:0}}
            placeholder=" "
            autoComplete="false"
        />
        <label className="did-floating-label">{props?.label ? props?.label : props?.name}</label>
    </div>

})
export default Input