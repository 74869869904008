import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "../Task/Details";
import Info from "../regular/Info";

export default function Project(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState();

    let projectState = useSelector((state) => state.project?.data)
    let dispatch = useDispatch()
    const [filter, setFilter] = React.useState()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: 0 })
        console.log("project:", projectState)
    }, [])

    const headerList = [
        { key: 'id', label: 'Id', type: 'string' },
        { key: 'name', label: 'Project Name', type: 'string' },
        { key: 'created_at', label: 'Date', type: 'string' },
    ]
    const onSubmit = (e, data) => {
        console.log("data :", data)
        let action = data.id ? REQUESTS.TASK.PUT.ACTION : REQUESTS.TASK.POST.ACTION
        dispatch({
            type: action, payload: data, callback: (resp) => {
                dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: 0 })
            }
        })
    }
    const onClickSearch = (value) => {
        let query = makeSearchQuery(headerList.map((head) => head.key), value)
        dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: `project/tasks/0/?search_by=${query}` })
    }

    const onEdit = (data, showmodel, loader, setFormData) => {
        let project = data.project
        if (project) {
            delete project.id
        }
        let newData = { ...data, ...project }
        setFormData(newData)
        showmodel(true)
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: `project/tasks/0/?filter_by=${query}` })
    }

    const onDelete = (data) => {
        let action = REQUESTS.PROJECT.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.PROJECT.GET.ACTION, payload: 0 })
            }
        })
    }





    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
                <ViewSave
                    showHeader
                    headerList={headerList}
                    dataList={projectState?.results}
                    onClickFilter={onClickFilter}
                    onClickSearch={onClickSearch}
                    onSubmit={onSubmit}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    moreIcon={<MoreHorizOutlinedIcon sx={{ fontSize: 14, ...customCss.Btn.Warn }} />}
                    onMore={(data) => { setMore(data) }}
                    title="project"
                >
                    <Input maxLength={100} label={"Project"} name={"name"} required={true} />
                    <Input type={"hidden"} name={"type"} value={"project"} />
                </ViewSave>
            </Grid>
            <Grid item xs={12} md={6}>

            </Grid>
            <Grid item xs={12} md={2}>

            </Grid>
        </Grid>
    </>
}