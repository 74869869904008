
import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Card, Divider, Grid, ListItem, Typography } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { styled } from '@mui/system';
//import Details from "./Details";
import Info from "../regular/Info";

export default function Attendance(props) {
  const [showModal, setShowModal] = React.useState(false);
  const [more, setMore] = React.useState();
  const [currentValue, setCurrentValue] = React.useState();
  let AttendanceViewState = useSelector((state) => state.attendance?.data)
  let dispatch = useDispatch()
  React.useEffect(() => {
    dispatch({ type: REQUESTS.ATTENDANCE.GET.ACTION, payload: 0 })
  }, [])

  const headerList = [
    // { key: "index", label: 'Sl No', style: { color: 'blue' } },
    { key: 'full_name', label: 'Name', type: 'string' },
    { key: 'sunday', label: 'Sunday', type: 'string' },
    { key: "monday", label: "Monday", type: 'string' },
    { key: "tuesday", label: "Tuesday", type: 'string' },
    { key: 'wednesday', label: 'Wednesday', type: 'string' },
    { key: "thursday", label: "Thursday", type: 'string' },
    { key: "friday", label: "Friday", type: 'string' },
    { key: "saturday", label: "Saturday", type: "string" },
  ]
  const TC = styled(TableCell)({
    padding: 4,
    minWidth: 80
  })

  return <>
    <Grid container spacing={1}>
      <Grid item xs={12} md={7}>
        <Card sx={{ mb: 2, p: 1, borderRadius: 4, boxShadow: 'none' }}>
          <Typography sx={{ fontSize: 12 }}>
            <div>Please enter your ticket on each cell and press tab ,if you have one more ticket add ticket number with comma seperator.</div>
            <div>Attendance marked only against correct  ticket number</div>
            <p></p>
          </Typography>
        </Card>
        <ViewSave
          showSort={true}
          showHeader
          showActions
          headerList={headerList}
          dataList={AttendanceViewState?.results}
          renderSearch={() => { }}
          renderCol={(data, key, style) => {
            if (data == "") {
              return <td>{data[key] ? "present" : "absent"}</td>
            }
            if (data[key] == "holiday") {
              return <td style={{ paddingTop: 15 }}><Input readOnly style={{ background: "#fff4cb" }} /></td>
            }
            if (key == "full_name") {
              return <td style={{ wordWrap: 'break-word', minWidth: 80 }}>{data[key]}</td>
            }
            return <td style={{ paddingTop: 15 }}> <Input defaultValue={data[key]} onBlur={(e) => {
              if (currentValue != e.target.value && e.target.value != '') {
                data[key] = e.target.value
                let action = data.id ? REQUESTS.ATTENDANCE.PATCH.ACTION : REQUESTS.ATTENDANCE.POST.ACTION
                dispatch({ type: action, payload: data })
              }
            }} onFocus={(e) => { setCurrentValue(e.target.value) }} /></td>
          }}
          title="View Attendance"
          row={<tr></tr>}
          col={<td style={{ padding: 8 }} />}
          head={<th style={{ width: '0px', opacity: "0" }}></th>}
          cover={<Table></Table>}
          body={<TableBody></TableBody>}
          editBtn="+"
        >
        </ViewSave>
      </Grid>
    </Grid>
  </>

}