import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem, Stack, Table, TableCell, TableRow, TableBody, Typography, Chip } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import CommentIcon from '@mui/icons-material/Comment';
import Details from "./Details";
import Info from "../regular/Info";
import { gridDensityValueSelector } from "@mui/x-data-grid";
import HBox from "../extra/HBox";
import { useLocation } from "react-router-dom";
import ZModal from "../../libs/ZModal";
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export default function UserTask(props) {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));
    let location = useLocation()
    let { data, back, id } = location.state ? location.state : {}
    let authState = useSelector((state) => state.auth.data)
    let projectState = useSelector((state) => state.project?.data)
    const [showModal, setShowModal] = React.useState(false);
    const [status, setStatus] = React.useState([]);
    const [more, setMore] = React.useState();
    let [taskState, setTaskState] = React.useState(data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        setStatus(["To Do", "In Progress", "Blocked", "Review", "Testing", "Closed", "Pause"])
    }, [])
    let userState = useSelector((state) => state.user?.data)
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.USER.GET.ACTION, payload: 0
        })
        console.log("user:", userState)
    }, [])
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.PROJECT.GET.ACTION, payload: 0
        })
        console.log("user:", userState)
    }, [])

    const headerList = [
        { key: 'name', label: 'Task', type: 'string' },
        {
            key: 'status', label: 'Status', type: 'boolean', values: status
        },
    ]

    const onSubmit = (e, data) => {
        if (data.image?.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.TASK.PUT.ACTION : REQUESTS.TASK.POST.ACTION
        dispatch({ type: action, payload: data })
    }

    const onClickSearch = (value) => {
        let query = makeSearchQuery(headerList.map((head) => head.key), value)
        dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: `tasks/0/?search_by=${query}` })
    }

    const onEdit = (data, showmodel, loader, setFormData) => {
        let task = data.task
        if (task) {
            delete task.id
        }
        let newData = { ...data, ...task }
        setFormData(newData)
        showmodel(true)
    }

    const onClickFilter = (e, data) => {
        let query = makeQuery(data.name, data.operator, data.value)
        dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: `tasks/0/?filter_by=${query}` })
    }

    const onClickSort = (column) => {
        dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: `tasks/0/?sort_by=${column}` })
    }

    const updateStatus = (data, e) => {
        let new_data = { id: data.id, status: e.target.value }
        let action = REQUESTS.TASK.PATCH.ACTION
        dispatch({
            type: action, payload: new_data, callback: () => {
                dispatch({
                    type: REQUESTS.TASK.GET.ACTION,
                    payload: `tasks/0/?filter_by=owner=${id}`, callback: (resp) => {
                        setTaskState(resp)
                    }
                })
            }
        })
    }
    const updateAssign = (data, e) => {
        let new_data = { id: data.id, owner: e.target.value }
        let action = REQUESTS.TASK.PATCH.ACTION
        dispatch({
            type: action, payload: new_data, callback: () => {
                dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: 0 })
            }
        })
        console.log("newdata:", new_data)
    }

    const onDelete = (data) => {
        let action = REQUESTS.TASK.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: 0 })
            }
        })
    }

    const onClickPagination = (number, filter) => {
        let query = makeQuery(filter.name, filter.operator, filter.value)
        dispatch({ type: REQUESTS.TASK.GET.ACTION, payload: `tasks/0/?page=${number}&filter_by=${query}` })
    }

    return <>
        <ZModal showModal={showModal} onAction={() => { setShowModal(false) }}>
            <Details data={more} />
        </ZModal>
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <ViewSave
                    showHeader
                    showSort
                    showPagination
                    totalPaginationCount={taskState?.count}
                    headerList={headerList}
                    dataList={taskState?.results}
                    onClickFilter={onClickFilter}
                    onClickSearch={onClickSearch}
                    onClickSort={onClickSort}
                    onSubmit={onSubmit}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    onClickPagination={onClickPagination}
                    hideEditBtn={!authState.permissions.includes("tasks.change_tickets")}
                    hideDelBtn={!authState.permissions.includes("tasks.delete_tickets")}
                    moreIcon={<CommentIcon sx={{ fontSize: 14, ...customCss.Btn.Warn }} />}
                    onMore={(data) => {
                        if (matches) {
                            dispatch({
                                type: REQUESTS.COMMENT.GET.ACTION, payload: `comment/tasks/${data?.id}/`, callback: (resp) => {
                                    console.log("comments", resp)
                                    setMore({ id: data.id, resp: resp })
                                }
                            })
                        }
                    }}
                    renderCol={(data, key) => {
                        if (key == "name") {
                            return <TableCell item>
                                <Box sx={{ maxWidth: 250, overflowX: 'auto', }}>
                                    <Chip size="small" sx={{ mb: 2, mx: 1 }} label={<Typography item sx={{ fontSize: 12 }}>TN :{data?.id}</Typography>} />
                                    <Chip size="small" color="warning" sx={{ mb: 2 }} label={<Typography item sx={{ fontSize: 12 }}>Amount :{data?.amount}</Typography>} />
                                    <Typography item sx={{ pb: 0.5, fontSize: 10, fontWeight: 700 }}>Project Name: {data?.parent_name ? data?.parent_name : "-"}</Typography>
                                    <Typography item sx={{ pb: 1, fontSize: 12 }}>Task: {data?.name}</Typography>

                                    <Typography sx={{ pb: 2, fontSize: 10, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: data?.description }}></Typography>
                                </Box>
                                <Button size="small" sx={{mt:1}} variant="outlined" color="primary" onClick={() => {
                                    if (!matches) {
                                        dispatch({
                                            type: REQUESTS.COMMENT.GET.ACTION, payload: `comment/tasks/${data?.id}/`, callback: (resp) => {
                                                console.log("comments", resp)
                                                setMore({ id: data.id, resp: resp })
                                                setShowModal(true)
                                            }
                                        })
                                    }
                                }}>Comments</Button>
                            </TableCell>
                        }
                        if (key == "status") {
                            return <TableCell sx={{ minWidth: 100 }}>

                                <Chip size="small" sx={{ mb: 1 }} label={<Typography fontSize={10} sx={{ color: 'red' }}>
                                    Deadline {data.deadline < 0 ? ":Not Defined" : new Date(data.deadline).toLocaleString()}
                                </Typography>}>
                                </Chip>

                                {data.days && <Chip size="small" sx={{ mb: 1 }} label={data.days} />}
                                <Typography fontSize={12}>Status</Typography>
                                <Input type={"select"} label="Status"
                                    options={status} value={data.status}
                                    onChange={(e) => { updateStatus(data, e) }} />
                                <Typography fontSize={12} sx={{ minWidth: 110 }}>Assigned to</Typography>
                                <Input type={"select"} label="Assigned to"
                                    options={userState?.results}
                                    value={data.owner}
                                    text={"first_name"} name={"owner"} val={"id"}
                                    onChange={(e) => { updateAssign(data, e) }} />
                            </TableCell>
                        }
                        else {
                            return <TableCell><Typography sx={{ py: 1, fontSize: 12 }}>{data[key]}</Typography></TableCell>
                        }
                    }}




                    cover={<Table sx={{ width: 0 }} />}
                    row={<TableRow />}
                    col={<TableCell />}
                    body={<TableBody />}
                    title="Task"
                >
                    {
                        authState.permissions.includes("tasks.add_tickets") && <>



                            <Input type={"select"} label="Project" options={projectState?.results} text={"name"} name={"parent"} val="id"></Input>
                            <Input maxLength={100} label={"Task"} name={"name"} required={true} />
                            <Input type={"textarea"} label={"Description (supported html element)"} name={"description"} required={true} />
                            <Input type={"select"} label="priority" options={["high", "urgent", "law", "medium"]} name={"priority"} ></Input>
                            <Input type={"select"} label="status" options={status} name={"status"}></Input>
                            <Input type={"select"} label="Points(1 points is 4 hours)" options={[1, 2, 4, 8, 12]} name={"points"}></Input>
                            <Input type={"select"} label="Assigned by" options={userState?.results} text={"first_name"} name={"assigned_by"} val={"id"} ></Input>
                            <Input type={"select"} label="assigned to" options={userState?.results} text={"first_name"} name={"owner"} val={"id"}></Input>
                            <Input type={"number"} defaultValue="10" min={0} step={1} maxLength={100} label={"Amount of ticket"} name={"amount"} required={true} />

                        </>}
                </ViewSave>

            </Grid>
            <Grid item xs={12} md={6}>
                {more && <Details data={more} onSubmit={onSubmit} />}
            </Grid>
        </Grid >
    </>




}