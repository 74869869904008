import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { Stack, Table, TableCell, TableRow, TableBody, Typography, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "./Details";
import Info from "../regular/Info";

export default function PubTerms$Conditions(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState();
    let termState = useSelector((state) => state.termsandconditionpub?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({ type: REQUESTS.TERMSPUB.GET.ACTION, payload: 0, })
        console.log("terms data:", termState)
    }, [])
    const headerList = [
        { key: "termsandcondition", label: "terms", type: "string" },
    ]
    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
                <ViewSave
                    //showHeader
                    renderSearch={() => <></>}
                    hideEditBtn
                    hideDelBtn
                    //onSubmit={onSubmit}

                    headerList={headerList}
                    dataList={termState?.results}
                    renderCol={(data, key) => {
                        if (key == "termsandcondition") {
                            return <TableCell item>
                                <Box sx={{ maxWidth: "100%", overflowX: 'auto' }}>
                                    <Typography sx={{ pb: 0, fontSize: 12, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: data?.termsandcondition }}></Typography>
                                </Box>
                            </TableCell>
                        }
                    }
                    }>
                </ViewSave>
            </Grid>
        </Grid>
    </>

}
