import React from 'react';
import { css as customCss } from '../../content/Style'
import { Input, ViewSave, XForm } from "../../libs/Libs"
import AutoStoriesIcon from '@mui/icons-material/AutoStories';
import { ListItem } from '@mui/material';
import { Box, Button, Divider, Grid, Badge, Card, CardMedia, CardContent, Chip, Stack } from "@mui/material";
const img404 = ""
function Details(props) {
    let { data } = props;
    let { id, resp } = data;
    let isValid = (data != undefined && Object.keys(data).length > 0);

    const headerList = [
        { key: "image", type: "image", label: "image" },

        {
            key: 'description', label: 'Description', type: 'string',

        },
        {
            key: 'created_at', label: 'created at', type: 'string',

        },

    ]

    return (
        <>

            <Grid container>

                <Grid item sx={{ p: 1 }} md={8}>
                    <h5 style={{ textTransform: "uppercase" }}>{data?.parent_name}</h5>
                </Grid>
            </Grid>

            <ViewSave
                showHeader
                dataList={resp?.results}
                headerList={headerList}
                onSubmit={props.onSubmit}

                renderCol={(data, key, style) => {
                    if (key == "image") {
                        return <ListItem>

                            <a href={data.image} target="_blank" >
                                <img src={data.image} width="100" style={{ borderRadius: 2 }} />
                            </a>

                        </ListItem>
                    }
                    return < ListItem >
                        <span dangerouslySetInnerHTML={{ __html: data[key] }}></span>
                    </ListItem>
                }
                }
                //icon="image"
                title="comments"
            >


                <Input type={"hidden"} name={"parent"} value={id} />
                <Input type={"hidden"} name={"name"} value={"comment"} />
                <Input type={"hidden"} name={"type"} value={"comment"} />
                {/* <Input type={"select"} label="assigned to" options={userState?.results} text={"first_name"}></Input> */}
                <Input type={"textarea"} label={"comment"} name={"description"} />
                <Input type={"image"} label={"image"} name={"image"} />



            </ViewSave>







        </>
    )
}

export default Details