import { createAction } from '../libs/redux/saga'
import { setAuthProperty } from '../libs/redux/saga/reducer';
export const AUTH_DATA = "auth";
export const LOGIN = "LOGIN";
setAuthProperty(LOGIN, AUTH_DATA)

// ---  Your Part Do not update above code----
export const PERFORMANCE = "PERFORMANCE"
export const CHART = "CHART";
export const LOGOUT = "LOGOUT";
export const ISLOGGED = "ISLOGGED";
export const GROUP = "GROUP";
export const USERGROUP = "USERGROUP";
export const DASHBOARD = "DASHBOARD";
export const MODELPROPS = "MODELPROPS";
export const REPORT = "REPORT";
export const USER = "USER";
export const FORGOT = "FORGOT";
export const BRANCH = "BRANCH";
//export const LOAN = "LOAN";
export const FILEUPLOAD = "FILEUPLOAD";
export const CENTER = "CENTER";
export const AGENT = "AGENT";
export const CUSTOMER = "CUSTOMER";
export const CANDIDATE = "CANDIDATE";
export const TASK = "TASK";
export const ATTENDANCE = "ATTENDANCE";
export const CALENDAR = "CALENDAR";
export const PROJECT = "PROJECT"
export const COMMENT = "COMMENT"
export const SETTINGS = "SETTINGS"
export const TERMS="TERMS"
export const TERMSPUB="TERMSPUB"
export const MAIL = "MAIL";
export const REQUESTS = {
    ...createAction(LOGOUT, "", AUTH_DATA),
    ...createAction(ISLOGGED, "", AUTH_DATA),
    ...createAction(LOGIN, "token", AUTH_DATA),
    ...createAction(CHART, "chart", "chart"),
    ...createAction(GROUP, "group", "group", { success: "User group updated", error: "Failed to add group" }),
    ...createAction(USERGROUP, "user/group", "usergroup", { success: "User group changed", error: "Failed to change group" }),
    ...createAction(DASHBOARD, "dashboard", "dashboard"),
    ...createAction(REPORT, "report", "report", { success: "Report updated successfully", error: "Failed to add report" }),
    ...createAction(USER, "user", "user", { success: "User updated successfully", error: "Failed to add user" }),
    ...createAction(TASK, "tasks", "task", { success: "task updated successfully", error: "failed to add task" }),
    ...createAction(PROJECT, "project/tasks", "project", { success: "project updated successfully", error: "failed to add project" }),
    ...createAction(COMMENT, "comment/tasks", "comment", { success: "comment updated successfully", error: "failed to add comment" }),
    ...createAction(ATTENDANCE, "attendance", "attendance", { sucess: "attendance updated successfully", error: "failed to update attendance" }),
    ...createAction(FORGOT, "forgotpassword", "forgot"),
    ...createAction(CANDIDATE, "candidate", "candidate", { success: "Candidate Details Succesfully Updated", error: "Failed to Update Details" }),
    ...createAction(PERFORMANCE, "performance", "performance", { success: "performance added succefully", error: "failed to update performance" }),
    ...createAction(SETTINGS, "settings", "settings", { success: "settings updated successfully", error: "Failed to add settings" }),
    ...createAction(TERMS, "termsandcondition", "termsandconditions", { success: "updated successfully", error: "failed to update" }),
    ...createAction(TERMSPUB,"public/termsandcondition","termsandconditionpub",{success:"updated successfully",error:"failed to update"}),
    ...createAction(SETTINGS, "settings", "settings", { success: "settings updated successfully", error: "Failed to add settings" }),
    ...createAction(AGENT, "agent", "agent", { success: "Agent updated successfully", error: "Failed to add Agent" }),
    ...createAction(CALENDAR, "calendar", "calendar"),
}