import * as React from 'react';
import Menu from '@mui/material/Menu';
import { Box, Button, Divider, Grid, Stack } from '@mui/material';
import Input from './inputs/Input';
import { XForm } from './Libs';
import { css as customCss } from '../content/Style';
export default function ZFilter({ icon, columns, operators, onChange }) {
    icon = icon ? icon : "Search & Filter"
    const [type, setType] = React.useState([])
    const [opts, setOpts] = React.useState([])
    const [selOpt, setSelOpt] = React.useState()
    onChange = onChange ? onChange : (e, data) => { console.log("SUBMIT DATA", data) }
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    columns = columns ? columns : columns = [
        { label: "ID", type: "number", key: "id" },
        { label: "Name", type: "string", key: 'name' },
        { label: "Date", type: "number", key: 'date' },
    ];
    operators = operators ? operators : operators = {
        number: ["=", ">", "<", "!=", "", "Is Empty", "Is Not Empty", "Is Any Of"].map((c) => { return { value: c, "label": c } }),
        date: ["=", ">", "<", "!=", "", "Is Empty", "Is Not Empty", "Between", "Is Any Of"].map((c) => { return { value: c, "label": c } }),
        string: [
            "Contains",
            "Equals",
            "Starts With",
            "Ends With",
            "Is Empty",
            "Is Not Empty",
            "Is Any Of",
        ].map((c) => { return { value: c, "label": c } }),
    };
    const mergedData = columns.map((column, i) => {
        if (column.key == "actions") {
            delete columns[i]
            return
        }
        if (column.type == "boolean") {
            column["type_values"] = column.values;
            return
        }
        column["type_values"] = operators[column.type ? column.type : "string"]
        return column
    });


    return (
        <div>
            <span style={{ cursor: "pointer" }} onClick={handleClick}>{icon}</span>
            <Menu
                style={{ borderRadius: 10}}
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <Box sx={{ p: 2, borderRadius: 8 }}>
                    <XForm onSubmit={onChange}>
                        <Divider textAlign="left" sx={{ fontSize: 10 }}>Filter</Divider>
                        <Stack sx={{ my: 0.5}} spacing={1} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                            <Input type="select"
                                name={"name"} label={"Column"}
                                options={columns} text={"label"}
                                val={"key"}
                                onChange={(e) => {
                                    try {
                                        let selColumn = columns.filter((column) => { return column.key == e.target.value });
                                        console.log("types==", selColumn, "selected", e.target.value)
                                        setOpts(selColumn[0]?.type_values)
                                        setType(selColumn[0]?.type)
                                    } catch (e) { }
                                }
                                }
                            />
                            {type == 'boolean' ? <>
                                <Input type="hidden" name="operator" value="="/>
                                <Input type="select"
                                    name={"value"} label={"Value"}
                                    options={opts}
                                    text={"label"}
                                    val={"value"}
                                    onChange={(e) => {
                                        console.log(e.target.value, e.target.options[e.target.selectedIndex].text)
                                        setSelOpt(e.target.value)
                                    }} /> </> :
                                <Input type="select"
                                    name={"operator"} label={"Operator"}
                                    options={opts}
                                    text={"label"}
                                    val={"value"}
                                    onChange={(e) => {
                                        console.log(e.target.value, e.target.options[e.target.selectedIndex].text)
                                        setSelOpt(e.target.value)
                                    }} />
                            }
                        </Stack>
                        {!['boolean'].includes(type) && !["Is Empty", "Is Not Empty"].includes(selOpt) && <Stack sx={{ my: 0.5 }} spacing={1} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                            <Input type={type} name={"value"} label={selOpt == 'Between' ? "From" : "Value"} />
                            {selOpt == 'Between' && <Input type={type} name={"value2"} label={"To"} />}
                        </Stack>}
                        <Stack spacing={1} sx={{ my: 0.5 }} direction={"row"} divider={<Divider orientation="vertical" flexItem />}>
                            <Button type={"Submit"}>Filter</Button>
                        </Stack>
                    </XForm>
                </Box>
            </Menu>
        </div>
    );
}