import * as React from "react";
import { Input, ViewSave, checkIsImageURL, makeSearchQuery, makeQuery } from "../../libs/Libs"
import { Stack, Table, TableCell, TableRow, TableBody, Typography, Chip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { REQUESTS } from "../../services/types";
import { Box, Button, Divider, Grid, ListItem } from "@mui/material";
import { css as customCss } from '../../content/Style'
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
//import Details from "./Details";
import Info from "../regular/Info";

export default function Terms$Conditions(props) {
    const [showModal, setShowModal] = React.useState(false);
    const [more, setMore] = React.useState();
    let termState = useSelector((state) => state.termsandconditions?.data)
    let dispatch = useDispatch()
    React.useEffect(() => {
        dispatch({
            type: REQUESTS.TERMS.GET.ACTION, payload: 0,


        }

        )
        console.log("terms data:", termState)

    }, [])

    const headerList = [
        { key: "termsandcondition", label: "terms", type: "string" },


    ]
    const onSubmit = (e, data) => {
        console.log("data :", data)

        if (data.image?.indexOf("https://") > -1) {
            console.error("Not a base 64")
            delete data.image
        }
        let action = data.id ? REQUESTS.TERMS.PUT.ACTION : REQUESTS.TERMS.POST.ACTION
        dispatch({ type: action, payload: data })
    }
    const onDelete = (data) => {
        let action = REQUESTS.TERMS.DELETE.ACTION
        dispatch({
            type: action, payload: data.id, callback: () => {
                dispatch({ type: REQUESTS.TERMS.GET.ACTION, payload: 0 })
            }
        })
    }
    return <>
        <Grid container spacing={1}>
            <Grid item xs={12} md={6}>
                <ViewSave
                    showHeader
                    onSubmit={onSubmit}
                    onDelete={onDelete}
                    headerList={headerList}
                    dataList={termState?.results}
                    renderCol={(data, key) => {
                        if (key == "termsandcondition") {
                            return <TableCell item sx={{ minWidth: '320px', overflowX: 'auto' }}>
                                <Box >
                                    <Typography sx={{ pb: 2, fontSize: 10, wordWrap: 'break-word' }} dangerouslySetInnerHTML={{ __html: data?.termsandcondition }}></Typography>
                                </Box>
                            </TableCell>
                        }
                    }}
                    title="Terms and conditions"
                >
                    <Input type={"textarea"} label={"Terms and conditions"} name={"termsandcondition"} required={true} />
                </ViewSave>
            </Grid>
        </Grid>
    </>

}
